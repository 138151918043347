export const IS_CLOSED = process.env.VUE_APP_IS_CLOSED === 'true'
export const IS_LOCAL = process.env.VUE_APP_IS_LOCAL === 'true'

export const SITE_NAME = process.env.VUE_APP_SITE_NAME || 'kameker3 ADMIN'
export const API_NAME = 'kamerker2API'

export const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY || ''
export const GOOGLE_MAPS_API_VERSION = 3

export const MAIN_COLOR = process.env.VUE_APP_MAIN_COLOR || '#003076'
export const LATEST_DEVICE_ALERT_LOGS_DISPLAY_COUNT = 10
export const TIME_IS_ZERO = '0001-01-01T00:00:00Z'

export const ADMIN_GROUP_NAME = 'admin'

export const DEVICE_STATUS_ENABLED = 'enabled'
export const DEVICE_STATUS_DISABLED = 'disabled'

export const MAP_DISPLAY_STATUS_ALL = 'all'
export const MAP_DISPLAY_STATUS_GROUP = 'group'
export const MAP_DISPLAY_STATUS_HIDE = 'hide'
export const MAP_DISPLAY_STATUS_LIST = [
  {
    label: '全ユーザーに対して表示',
    value: MAP_DISPLAY_STATUS_ALL
  },
  {
    label: 'グループに対してのみ表示',
    value: MAP_DISPLAY_STATUS_GROUP
  },
  {
    label: '非表示',
    value: MAP_DISPLAY_STATUS_HIDE
  }
]

export const NOTIFICATION_STATUS_ALL = 'all'
export const NOTIFICATION_STATUS_GROUP = 'group'
export const NOTIFICATION_STATUS_TEST = 'test'
export const NOTIFICATION_STATUS_NONE = 'none'
export const NOTIFICATION_STATUS_LIST = [
  {
    label: '全ユーザーに対して通知',
    value: NOTIFICATION_STATUS_ALL
  },
  {
    label: 'グループに対してのみ通知',
    value: NOTIFICATION_STATUS_GROUP
  },
  {
    label: 'メンバーにのみテスト送信',
    value: NOTIFICATION_STATUS_TEST
  },
  {
    label: '通知しない',
    value: NOTIFICATION_STATUS_NONE
  }
]

export const GROUP_ROLE_GENERAL = 'general'
export const GROUP_ROLE_ADMIN = 'admin'
export const GROUP_ROLE_CUSTOM = 'custom'

export const USER_ROLE_GENERAL = 'general'
export const USER_ROLE_ADMIN = 'admin'
export const USER_ROLE_LIST = [
  { name: USER_ROLE_GENERAL, label: '一般' },
  { name: USER_ROLE_ADMIN, label: 'メンバー' }
]

export const DEFAULT_LINE_OFFICIAL_ACCOUNT_NAME = 'kameker3'

export const MESSAGE_DELIVERY_STATUS_RESERVED = 'reserved'
export const MESSAGE_DELIVERY_STATUS_CANCELED = 'canceled'
export const MESSAGE_DELIVERY_STATUS_COMPLETED = 'completed'
export const MESSAGE_DELIVERY_STATUS_SENDING = 'sending'
export const MESSAGE_DELIVERY_STATUS_ERROR = 'error'
export const MESSAGE_DELIVERY_STATUS_LIST = [
  {
    label: '予約',
    value: MESSAGE_DELIVERY_STATUS_RESERVED
  },
  {
    label: 'キャンセル',
    value: MESSAGE_DELIVERY_STATUS_CANCELED
  },
  {
    label: '完了',
    value: MESSAGE_DELIVERY_STATUS_COMPLETED
  },
  {
    label: '送信中',
    value: MESSAGE_DELIVERY_STATUS_SENDING
  },
  {
    label: 'エラー',
    value: MESSAGE_DELIVERY_STATUS_ERROR
  }
]

export const MESSAGE_DELIVERY_TARGET_TYPE_USER = 'user'
export const MESSAGE_DELIVERY_TARGET_TYPE_MEMBER_USER = 'member_user'
export const MESSAGE_DELIVERY_TARGET_TYPE_GROUP = 'group'
export const MESSAGE_DELIVERY_TARGET_TYPE_DEVICE = 'device'
export const MESSAGE_DELIVERY_TARGET_TYPE_LIST = [
  {
    label: 'ユーザー',
    value: MESSAGE_DELIVERY_TARGET_TYPE_USER
  },
  {
    label: 'メンバー（管理担当者）',
    value: MESSAGE_DELIVERY_TARGET_TYPE_MEMBER_USER
  },
  {
    label: 'グループ',
    value: MESSAGE_DELIVERY_TARGET_TYPE_GROUP
  },
  {
    label: 'センサ',
    value: MESSAGE_DELIVERY_TARGET_TYPE_DEVICE
  }
]

export const LOG_EXPORT_TARGET_TYPE_ALERT = 'alert'
export const LOG_EXPORT_TARGET_TYPE_NOTIFICATION = 'notification'
export const LOG_EXPORT_TARGET_TYPE_DEVICE_ALIVE_ALERT = 'device_alive_alert'
export const LOG_EXPORT_TARGET_TYPE_LIST = [
  {
    label: 'アラート',
    value: LOG_EXPORT_TARGET_TYPE_ALERT
  },
  {
    label: '通知',
    value: LOG_EXPORT_TARGET_TYPE_NOTIFICATION
  },
  {
    label: '自己診断異常',
    value: LOG_EXPORT_TARGET_TYPE_DEVICE_ALIVE_ALERT
  }
]

export const LOG_EXPORT_STATUS_RESERVED = 'reserved'
export const LOG_EXPORT_STATUS_COMPLETED = 'completed'
export const LOG_EXPORT_STATUS_RUNNING = 'running'
export const LOG_EXPORT_STATUS_ERROR = 'error'

export const LOG_EXPORT_STATUS_LIST = [
  {
    label: '予約',
    value: LOG_EXPORT_STATUS_RESERVED
  },
  {
    label: '完了',
    value: LOG_EXPORT_STATUS_COMPLETED
  },
  {
    label: '実行中',
    value: LOG_EXPORT_STATUS_RUNNING
  },
  {
    label: 'エラー',
    value: LOG_EXPORT_STATUS_ERROR
  }
]

export const DISPLAY_TYPE_MAP = 'map'
export const DISPLAY_TYPE_LIST = 'list'
export const DISPLAY_TYPE_DRAWING = 'drawing'
export const DISPLAY_TYPES = [
  {
    label: 'GoogleMap',
    value: DISPLAY_TYPE_MAP,
    disabled: false
  },
  {
    label: '一覧表示',
    value: DISPLAY_TYPE_LIST,
    disabled: false
  },
  {
    label: '図面表示',
    value: DISPLAY_TYPE_DRAWING,
    disabled: true
  }
]

export const FIXED_PHRASE_LIST = [
  {
    label: '緊急避難',
    value: '【緊急】\nセンサで浸水を検知しました！至急避難してください！'
  },
  {
    label: 'センサ動作テスト',
    value:
      '【お知らせ】\nただ今からセンサの動作テストを行います。\nしばらくセンサから通知が来ますが浸水ではありませんのでご安心ください。\n動作テストが終了しましたら、再度お知らせさせていただきます。'
  },
  {
    label: 'センサ動作テスト終了',
    value:
      '【お知らせ】\nセンサの動作テストは終了しました。ご協力ありがとうございました。'
  }
]

export const VALIDATION_MAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const DEVICE_ALERT_TYPE_LIST = [
  {
    label: 'メール通知・LINE通知',
    value: 'all'
  },
  {
    label: 'LINE通知',
    value: 'line'
  },
  {
    label: 'メール通知',
    value: 'mail'
  }
]

export const DEVICE_ALERT_TYPE_LIST_FOR_NOTIFICATION_LOG = [
  {
    label: 'メール通知・LINE通知',
    value: 'line,mail'
  },
  {
    label: 'LINE通知',
    value: 'line'
  },
  {
    label: 'メール通知',
    value: 'mail'
  }
]

export const marshalDevice = (body, type) => {
  return {
    type: type,
    id: body.id,
    name: body.name,
    group: body.group,
    series_name: body.series_name,
    imsi: Number(body.imsi),
    imei: Number(body.imei),
    position: {
      lat: Number(body.position.lat),
      lng: Number(body.position.lng)
    },
    agent: body.agent,
    product_serial_number: body.product_serial_number,
    circuit_board_serial_number: body.circuit_board_serial_number,
    device_status: body.device_status,
    map_display_status: body.map_display_status,
    notification_status: body.notification_status,
    latest_alerted_at: body.latest_alerted_at,
    is_device_alive_alert: body.is_device_alive_alert,
    is_long_battery_device: body.is_long_battery_device,
    description: body.description
  }
}

export const marshalDeviceUpdateSetting = (body, type) => {
  return {
    type: type,
    id: body.id,
    notification_message_template: body.notification_message_template,
    test_notification_message_template: body.test_notification_message_template,
    device_alerting_interval_second: Number(
      body.device_alerting_interval_second
    ),
    user_device_notification_interval_second: Number(
      body.user_device_notification_interval_second
    ),
    notification_suppression_by_second: Number(
      body.notification_suppression_by_second
    ),
    notification_suppression_count: Number(body.notification_suppression_count),
    notification_suppression_disable_alert_second: Number(
      body.notification_suppression_disable_alert_second
    ),
    notification_message_template_for_email:
      body.notification_message_template_for_email,
    test_notification_message_template_for_email:
      body.test_notification_message_template_for_email,
    notification_subject_template_for_email:
      body.notification_subject_template_for_email,
    test_notification_subject_template_for_email:
      body.test_notification_subject_template_for_email,
    user_device_notification_interval_second_for_email: Number(
      body.user_device_notification_interval_second_for_email
    ),
    notification_suppression_by_second_for_email: Number(
      body.notification_suppression_by_second_for_email
    ),
    notification_suppression_count_for_email: Number(
      body.notification_suppression_count_for_email
    ),
    notification_suppression_disable_alert_second_for_email: Number(
      body.notification_suppression_disable_alert_second_for_email
    )
  }
}

export const marshalSetting = body => {
  return {
    notification_message_template: body.notification_message_template,
    test_notification_message_template: body.test_notification_message_template,
    device_alerting_interval_second: Number(
      body.device_alerting_interval_second
    ),
    user_device_notification_interval_second: Number(
      body.user_device_notification_interval_second
    ),
    notification_suppression_by_second: Number(
      body.notification_suppression_by_second
    ),
    notification_suppression_count: Number(body.notification_suppression_count),
    notification_suppression_disable_alert_second: Number(
      body.notification_suppression_disable_alert_second
    ),
    number_of_alerts_to_notify_second: Number(
      body.number_of_alerts_to_notify_second
    ),
    number_of_alerts_to_notify_count: Number(
      body.number_of_alerts_to_notify_count
    ),
    device_alive_threshold_hour: Number(body.device_alive_threshold_hour),
    device_alive_alert_message_template:
      body.device_alive_alert_message_template,
    notification_message_template_for_email:
      body.notification_message_template_for_email,
    test_notification_message_template_for_email:
      body.test_notification_message_template_for_email,
    notification_subject_template_for_email:
      body.notification_subject_template_for_email,
    test_notification_subject_template_for_email:
      body.test_notification_subject_template_for_email,
    device_alert_subscribe_confirm_message_template_for_email:
      body.device_alert_subscribe_confirm_message_template_for_email,
    device_alert_subscribe_confirm_subject_template_for_email:
      body.device_alert_subscribe_confirm_subject_template_for_email,
    user_device_notification_interval_second_for_email: Number(
      body.user_device_notification_interval_second_for_email
    ),
    notification_suppression_by_second_for_email: Number(
      body.notification_suppression_by_second_for_email
    ),
    notification_suppression_count_for_email: Number(
      body.notification_suppression_count_for_email
    ),
    notification_suppression_disable_alert_second_for_email: Number(
      body.notification_suppression_disable_alert_second_for_email
    ),
    threshold_for_red_battery: Number(body.threshold_for_red_battery)
  }
}

export const marshalGroup = (body, type) => {
  return {
    type: type,
    id: body.id,
    name: body.name,
    enabled: body.enabled,
    description: body.description,
    role: body.role,
    line_official_account_name: body.line_official_account_name,
    idp_identifier: body.idp_identifier,
    line_messaging_api_channel_access_token:
      body.line_messaging_api_channel_access_token,
    line_messaging_api_channel_secret: body.line_messaging_api_channel_secret,
    line_login_channel_id: body.line_login_channel_id,
    line_login_channel_secret: body.line_login_channel_secret,
    admin_line_login_channel_id: body.admin_line_login_channel_id,
    admin_line_login_channel_secret: body.admin_line_login_channel_secret,
    display_type: body.display_type
  }
}

export const marshalGroupUpdateSetting = (body, type) => {
  return {
    type: type,
    id: body.id,
    notification_message_template: body.notification_message_template,
    test_notification_message_template: body.test_notification_message_template,
    device_alerting_interval_second: Number(
      body.device_alerting_interval_second
    ),
    user_device_notification_interval_second: Number(
      body.user_device_notification_interval_second
    ),
    notification_suppression_by_second: Number(
      body.notification_suppression_by_second
    ),
    notification_suppression_count: Number(body.notification_suppression_count),
    notification_suppression_disable_alert_second: Number(
      body.notification_suppression_disable_alert_second
    ),
    device_alive_alert_type: body.device_alive_alert_type,
    device_alive_alert_mail_list: body.device_alive_alert_mail_list,
    device_alert_type: body.device_alert_type,
    device_alert_mail_list: body.device_alert_mail_list,
    notification_message_template_for_email:
      body.notification_message_template_for_email,
    test_notification_message_template_for_email:
      body.test_notification_message_template_for_email,
    notification_subject_template_for_email:
      body.notification_subject_template_for_email,
    test_notification_subject_template_for_email:
      body.test_notification_subject_template_for_email,
    user_device_notification_interval_second_for_email: Number(
      body.user_device_notification_interval_second_for_email
    ),
    notification_suppression_by_second_for_email: Number(
      body.notification_suppression_by_second_for_email
    ),
    notification_suppression_count_for_email: Number(
      body.notification_suppression_count_for_email
    ),
    notification_suppression_disable_alert_second_for_email: Number(
      body.notification_suppression_disable_alert_second_for_email
    )
  }
}

export const downloadCSVByResponse = ({ body, file_name }) => {
  // csvダウンロード処理
  const blob = new Blob([body], { type: 'text/csv' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = file_name
  link.target = '_blank'
  link.click()
}

import Vue from 'vue'
import Router from 'vue-router'
import {
  guardByHome,
  guardByAuth,
  guardByAdmin,
  handleSignOut,
  handleLineIdpresponse
} from '@/middleware/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/pages/index.vue'),
      redirect: () => {
        return '/home'
      },
      beforeEnter: guardByHome,
      children: [
        {
          path: '/home',
          name: 'home-index',
          component: () => import('@/pages/home/index.vue')
        },
        {
          path: '/device',
          name: 'device-index',
          component: () => import('@/pages/device/index.vue')
        },
        {
          path: '/user',
          name: 'user-index',
          component: () => import('@/pages/user/index.vue')
        },
        {
          path: '/admin-user',
          name: 'admin-user-index',
          component: () => import('@/pages/admin-user/index.vue')
        },
        {
          path: '/group',
          name: 'group-index',
          component: () => import('@/pages/group/index.vue')
        },
        {
          path: '/message',
          name: 'message-index',
          component: () => import('@/pages/message/index.vue')
        },
        {
          path: '/log',
          name: 'log-index',
          component: () => import('@/pages/log/index.vue')
        },
        {
          path: '/setting',
          name: 'setting-index',
          component: () => import('@/pages/setting/index.vue'),
          beforeEnter: guardByAdmin
        }
      ]
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('@/pages/auth.vue'),
      redirect: () => {
        return '/auth/sign-in'
      },
      children: [
        {
          path: '/auth/sign-in',
          name: 'authSignIn',
          component: () => import('@/pages/auth/sign-in.vue'),
          beforeEnter: guardByAuth
        },
        {
          path: '/auth/sign-out',
          name: 'authSigniOut',
          beforeEnter: handleSignOut
        },
        {
          path: '/auth/forgot-password',
          name: 'authForgotPassword',
          component: () => import('@/pages/auth/forgot-password.vue'),
          beforeEnter: guardByAuth
        }
      ]
    },
    {
      path: '/device-alert-disable/',
      name: 'device-alert-disable',
      component: () => import('@/pages/device-alert-disable.vue')
    },
    {
      path: '/device-alert-subscription-confirm',
      name: 'device-alert-subscription-confirm',
      component: () => import('@/pages/device-alert-subscription-confirm.vue')
    }
  ]
})

export default router

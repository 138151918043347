export const SET_ACCOUNT = 'SET_ACCOUNT'

export const SET_DEVICES = 'SET_DEVICES'
export const ADD_DEVICES = 'ADD_DEVICES'
export const UPDATE_DEVICES = 'UPDATE_DEVICES'
export const DELETE_DEVICES = 'DELETE_DEVICES'

export const SET_DEVICE_ALERT_LOGS = 'SET_DEVICE_ALERT_LOGS'

export const SET_NOTIFICATION_MESSAGE_LOGS = 'SET_NOTIFICATION_MESSAGE_LOGS'

export const SET_USERS = 'SET_USERS'
export const UPDATE_USERS = 'UPDATE_USERS'
export const DELETE_USERS = 'DELETE_USERS'

export const SET_ADMIN_USERS = 'SET_ADMIN_USERS'
export const ADD_ADMIN_USERS = 'ADD_ADMIN_USERS'
export const UPDATE_ADMIN_USERS = 'UPDATE_ADMIN_USERS'
export const DELETE_ADMIN_USERS = 'DELETE_ADMIN_USERS'

export const SET_GROUPS = 'SET_GROUPS'
export const ADD_GROUPS = 'ADD_GROUPS'
export const UPDATE_GROUPS = 'UPDATE_GROUPS'
export const DELETE_GROUPS = 'DELETE_GROUPS'

export const SET_SETTINGS = 'SET_SETTINGS'

export const SET_MESSAGE_DELIVERIES = 'SET_MESSAGE_DELIVERIES'
export const ADD_MESSAGE_DELIVERY = 'ADD_MESSAGE_DELIVERY'
export const UPDATE_MESSAGE_DELIVERY = 'UPDATE_MESSAGE_DELIVERY'
export const DELETE_MESSAGE_DELIVERY = 'DELETE_MESSAGE_DELIVERY'

export const SET_LOG_EXPORTS = 'SET_LOG_EXPORTS'
export const ADD_LOG_EXPORT = 'ADD_LOG_EXPORT'
export const DELETE_LOG_EXPORT = 'DELETE_LOG_EXPORT'

export const SET_INFO_DEVICE_ID = 'SET_INFO_DEVICE_ID'

export const UPDATE_RELOAD_MAP = 'UPDATE_RELOAD_MAP'

export const SET_FULLSCREEN_LOADING = 'SET_FULLSCREEN_LOADING'

export const SET_SEARCH_DEVICES = 'SET_SEARCH_DEVICES'
export const SET_SEARCH_DEVICES_BY_GROUP = 'SET_SEARCH_DEVICES_BY_GROUP'

export default {
  ja: {
    'server-error':
      'サーバエラーが発生しました。管理者までお問い合わせください。',
    'password-error':
      'パスワードは 8文字以上、小・大文字を含む英数字、記号 を含める必要があります。',
    'Username cannot be empty': 'ユーザー名を入力してください',
    'The username should either be a string or one of the sign in types':
      'usernameに不正な文字が含まれています',
    'Password cannot be empty': 'パスワードを入力してください',
    'Confirmation code cannot be empty': '確認コードを入力してください',
    'Invalid verification code provided, please try again.':
      '無効な確認コードです。もう一度お試しください'
  }
}

export default {
  account: {
    id: '',
    username: '',
    group: '',
    groupName: '',
    groupRole: '',
    email: ''
  },
  devices: [],
  deviceAlertLogs: [],
  notificationMessageLogs: [],
  users: [],
  adminUsers: [],
  groups: [],
  settings: {
    // 空判定のためデフォルト値はいれない
    // notification_message_template: '',
    // test_notification_message_template: '',
    // device_alerting_interval_second: null,
    // user_device_notification_interval_second: null
  },
  messageDeliveries: [],
  logExports: [],
  // /home map上の現在選択されているdevice_id
  infoDeviceId: '',
  reloadMap: false,
  fullscreenLoading: false,
  searchDevices: '',
  searchDevicesByGroup: ''
}

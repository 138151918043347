import Vue from 'vue'
import Amplify, * as AmplifyModules from 'aws-amplify'
// import { AmplifyPlugin } from 'aws-amplify-vue'
import App from '@/app'
import { postLogger } from '@/api'
import awsconfig from '@/aws-exports'
import { IS_LOCAL } from '@/constants'
import amplifyi18n from '@/i18n/amplify'
import vuetify from '@/plugins/vuetify'
import router from '@/router/'
import store from '@/store/'

if (!IS_LOCAL) {
  Amplify.configure(awsconfig)
  AmplifyModules.I18n.putVocabularies(amplifyi18n)
  // Vue.use(AmplifyPlugin, AmplifyModules)
}

Vue.config.productionTip = false
Vue.config.performance = true
Vue.config.errorHandler = (err, vm, info) => {
  console.error(err)
  try {
    postLogger({
      timestamp: new Date().getTime(),
      level: 'ERROR',
      name: err.name || '',
      message: err.message || '',
      stack: err.stack || '',
      info: info || ''
    })
  } catch (e) {
    console.error(e)
  }
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

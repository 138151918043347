import {
  ADMIN_GROUP_NAME,
  DEVICE_STATUS_ENABLED,
  DEVICE_STATUS_DISABLED,
  LATEST_DEVICE_ALERT_LOGS_DISPLAY_COUNT,
  USER_ROLE_ADMIN,
  IS_LOCAL
} from '@/constants'

export default {
  // account.
  account: state => {
    return state.account
  },
  isAdminGroup: state => {
    if (IS_LOCAL) {
      return true
    }
    return state.account.groupRole === ADMIN_GROUP_NAME
  },
  accountGroup: state => {
    return state.account.groupName
  },
  accountGroupId: state => {
    return state.account.group
  },

  // device.
  devices: state => {
    return state.devices
  },
  deviceEnabledCount: state => {
    return state.devices.filter(d => d.device_status === DEVICE_STATUS_ENABLED)
      .length
  },
  deviceDisabledCount: state => {
    return state.devices.filter(d => d.device_status === DEVICE_STATUS_DISABLED)
      .length
  },
  deviceAlertCount: state => {
    return state.devices.filter(d => d.is_alert).length
  },
  deviceAliveAlertCount: state => {
    return state.devices.filter(d => d.is_device_alive_alert).length
  },
  deviceById: state => id => {
    return state.devices.find(d => d.id === id)
  },
  deviceNames: state => {
    const names = ['']
    state.devices.map(o =>
      names.push({
        id: o.id,
        name: o.name,
        group: o.group
      })
    )
    return names
  },
  deviceNameMap: state => {
    const nameMap = {}
    state.devices.map(o => (nameMap[o.name] = true))
    return nameMap
  },
  deviceNamesByGroup: state => groupId => {
    const names = ['']
    state.devices
      .filter(d => {
        return d.group === groupId
      })
      .map(o => {
        names.push({ id: o.id, name: o.name, group: o.group })
      })
    return names
  },

  // deviceAlertLog.
  deviceAlertLogs: state => {
    return state.deviceAlertLogs
  },
  deviceAlertLogsByLatest: state => {
    if (state.deviceAlertLogs <= LATEST_DEVICE_ALERT_LOGS_DISPLAY_COUNT) {
      return state.deviceAlertLogs
    }
    return state.deviceAlertLogs.slice(
      0,
      LATEST_DEVICE_ALERT_LOGS_DISPLAY_COUNT
    )
  },
  deviceAlertLogsBy: state => ({ key, value }) => {
    return state.deviceAlertLogs.filter(l => l[key] === value)
  },

  // notificationMessageLog.
  notificationMessageLogs: state => {
    return state.notificationMessageLogs
  },
  notificationMessageLogsBy: state => ({ key, value }) => {
    return state.notificationMessageLogs.filter(l => l[key] === value)
  },

  // user.
  users: state => {
    return state.users
  },
  userById: state => id => {
    return state.users.find(u => u.id === id)
  },
  userNames: state => {
    const names = ['']
    state.users.map(u =>
      names.push({
        id: u.id,
        name: u.name,
        group: u.group
      })
    )
    return names
  },
  userNamesByMember: state => {
    const names = ['']
    state.users
      .filter(u => u.role === USER_ROLE_ADMIN)
      .map(u =>
        names.push({
          id: u.id,
          name: u.name,
          group: u.group
        })
      )
    return names
  },

  // adminUser.
  adminUsers: state => {
    return state.adminUsers
  },
  adminUserById: state => id => {
    return state.adminUsers.find(u => u.id === id)
  },

  // group.
  groups: state => {
    return state.groups
  },
  groupNames: state => {
    const names = ['']
    state.groups.map(o => names.push({ id: o.id, name: o.name }))
    return names
  },
  groupNameMap: state => {
    const nameMap = {}
    state.groups.map(o => (nameMap[o.name] = true))
    return nameMap
  },
  groupNamesByGeneral: state => {
    const names = ['']
    state.groups.map(o => {
      if (o.role === 'custom') {
        return
      }
      names.push({ id: o.id, name: o.name })
    })
    return names
  },
  groupById: state => id => {
    return state.groups.find(g => g.id === id)
  },

  // settings
  settings: state => {
    return state.settings
  },
  settingItems: state => {
    return [
      {
        name: 'notification_message_template',
        value: state.settings.notification_message_template,
        description: 'アラート通知メッセージ',
        formType: 'textarea',
        suffix: ''
      },
      {
        name: 'test_notification_message_template',
        value: state.settings.test_notification_message_template,
        description: 'テストアラート通知メッセージ',
        formType: 'textarea',
        suffix: ''
      },
      {
        name: 'device_alerting_interval_second',
        value: state.settings.device_alerting_interval_second,
        description:
          'マップ上のセンサをアラート状態にする期間。最後にアラートのあった日時から分単位で設定',
        formType: 'number',
        suffix: '分'
      },
      {
        name: 'user_device_notification_interval_second',
        value: state.settings.user_device_notification_interval_second,
        description: 'ユーザーが通知を無効にできる期間。分単位で設定',
        formType: 'number',
        suffix: '分'
      },
      {
        name: 'notification_suppression_by_second',
        value: state.settings.notification_suppression_by_second,
        description: '通知抑制: 通知抑制対象の時間（分）',
        formType: 'number',
        suffix: '分'
      },
      {
        name: 'notification_suppression_count',
        value: state.settings.notification_suppression_count,
        description: '通知抑制: 対象時間内に何回アラートがあった場合か',
        formType: 'number',
        suffix: '回'
      },
      {
        name: 'notification_suppression_disable_alert_second',
        value: state.settings.notification_suppression_disable_alert_second,
        description: '通知抑制: 何分アラートを無効にするか',
        formType: 'number',
        suffix: '分'
      },
      {
        name: 'number_of_alerts_to_notify_second',
        value: state.settings.number_of_alerts_to_notify_second,
        description: '誤検知抑制: アラート回数カウント対象時間（分）',
        formType: 'radio',
        suffix: '分',
        values: [
          {
            label: '未設定',
            value: 0
          },
          {
            label: '２分',
            value: 2
          },
          {
            label: '３分',
            value: 3
          }
        ]
      },
      {
        name: 'number_of_alerts_to_notify_count',
        value: state.settings.number_of_alerts_to_notify_count,
        description: '誤検知抑制: 対象時間内の何回目のアラートで通知するか',
        formType: 'radio',
        suffix: '回',
        values: [
          {
            label: '未設定',
            value: 0
          },
          {
            label: '２回',
            value: 2
          },
          {
            label: '３回',
            value: 3
          }
        ]
      },
      {
        name: 'device_alive_threshold_hour',
        value: state.settings.device_alive_threshold_hour,
        description: '自己診断機能の判定期間（時間）',
        formType: 'number',
        suffix: '時間'
      },
      {
        name: 'device_alive_alert_message_template',
        value: state.settings.device_alive_alert_message_template,
        description: '自己診断機能アラート通知メッセージ',
        formType: 'textarea',
        suffix: ''
      },
      // for email
      {
        name: 'notification_message_template_for_email',
        value: state.settings.notification_message_template_for_email,
        description: '【メール用】アラート通知メッセージ',
        formType: 'textarea',
        suffix: ''
      },
      {
        name: 'test_notification_message_template_for_email',
        value: state.settings.test_notification_message_template_for_email,
        description: '【メール用】テストアラート通知メッセージ',
        formType: 'textarea',
        suffix: ''
      },
      {
        name: 'notification_subject_template_for_email',
        value: state.settings.notification_subject_template_for_email,
        description: '【メール用】アラート通知メッセージの件名',
        formType: 'text',
        suffix: ''
      },
      {
        name: 'test_notification_subject_template_for_email',
        value: state.settings.test_notification_subject_template_for_email,
        description: '【メール用】テストアラート通知メッセージの件名',
        formType: 'text',
        suffix: ''
      },
      {
        name: 'device_alert_subscribe_confirm_message_template_for_email',
        value:
          state.settings
            .device_alert_subscribe_confirm_message_template_for_email,
        description: '【メール用】Eメールアドレス確認メッセージ',
        formType: 'textarea',
        suffix: ''
      },
      {
        name: 'device_alert_subscribe_confirm_subject_template_for_email',
        value:
          state.settings
            .device_alert_subscribe_confirm_subject_template_for_email,
        description: '【メール用】Eメールアドレス確認メッセージの件名',
        formType: 'text',
        suffix: ''
      },
      {
        name: 'user_device_notification_interval_second_for_email',
        value:
          state.settings.user_device_notification_interval_second_for_email,
        description:
          '【メール用】ユーザーが通知を無効にできる期間。分単位で設定',
        formType: 'number',
        suffix: '分'
      },
      {
        name: 'notification_suppression_by_second_for_email',
        value: state.settings.notification_suppression_by_second_for_email,
        description: '【メール用】通知抑制: 通知抑制対象の時間（分）',
        formType: 'number',
        suffix: '分'
      },
      {
        name: 'notification_suppression_count_for_email',
        value: state.settings.notification_suppression_count_for_email,
        description:
          '【メール用】通知抑制: 対象時間内に何回アラートがあった場合か',
        formType: 'number',
        suffix: '分'
      },
      {
        name: 'notification_suppression_disable_alert_second_for_email',
        value:
          state.settings
            .notification_suppression_disable_alert_second_for_email,
        description: '【メール用】通知抑制: 何分アラートを無効にするか',
        formType: 'number',
        suffix: '分'
      },
      {
        name: 'threshold_for_red_battery',
        value: state.settings.threshold_for_red_battery,
        description:
          '【長期化バッテリー設定】バッテリー残量が0.25以下になってから赤色バッテリーにするまでの回数',
        formType: 'number',
        suffix: '回'
      }
    ]
  },

  // messageDeliveries
  messageDeliveries: state => {
    return state.messageDeliveries
  },
  messageDeliveryById: state => ({ groupId, messageId }) => {
    return state.messageDeliveries.find(m => {
      return m.group_id === groupId && m.message_id === messageId
    })
  },

  // logExports
  logExports: state => {
    return state.logExports
  },

  // infoDevice
  infoDeviceId: state => {
    return state.infoDeviceId
  },
  infoDevice: state => {
    if (state.infoDeviceId === '') {
      return {
        id: '',
        position: {
          lat: 0,
          lng: 0
        },
        name: '',
        is_alert: false,
        is_set: false,
        is_notification_disabled: false
      }
    }
    return state.devices.find(d => d.id === state.infoDeviceId)
  },

  // fullscreenLoading
  fullscreenLoading: state => {
    return state.fullscreenLoading
  },

  reloadMap: state => {
    return state.reloadMap
  },

  searchDevices: state => {
    return state.searchDevices
  },
  searchDevicesByGroup: state => {
    return state.searchDevicesByGroup
  }
}

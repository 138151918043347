import { API, Auth } from 'aws-amplify'
import { API_NAME, IS_LOCAL } from '@/constants'
import axios from 'axios'
import * as middleware from '@/middleware/api'

let localDevices
let localDeviceAlertLogs
let localNotificationMessageLogs
let localUsers
let localUser
let localAdminUsers
let localGroups
let localSettings
let localLogExports

if (IS_LOCAL) {
  localDevices = require('@/fixture/devices.json')
  localDeviceAlertLogs = require('@/fixture/device_alert_logs.json')
  localUsers = require('@/fixture/users.json')
  localGroups = require('@/fixture/groups.json')
  localSettings = require('@/fixture/settings.json')
  localLogExports = require('@/fixture/log_exports.json')
}

async function getAuthOption() {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken()
  return {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8;',
      Authorization: `Bearer ${token}`
    }
  }
}

// device
export const getDevices = async () => {
  if (IS_LOCAL) {
    return localDevices
  }
  const option = await getAuthOption()
  return API.get(API_NAME, '/devices', option)
}

export const getDevice = async deviceId => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  return API.get(API_NAME, `/device/${deviceId}`, option)
}

export const postDevice = async body => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = middleware.marshalDevice(body, 'init')
  return API.post(API_NAME, '/device', option)
}

export const putDevice = async (body, type) => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  switch (type) {
    case 'base':
      option.body = middleware.marshalDevice(body, type)
      break
    case 'setting':
      option.body = middleware.marshalDeviceUpdateSetting(body, type)
      break
  }
  return API.put(API_NAME, '/device', option)
}

export const deleteDevice = async id => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = {
    id: id
  }
  return API.del(API_NAME, '/device', option)
}

export const getDevicesCsvData = async () => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  return API.get(API_NAME, '/devices/csv-export', option)
}

// deviceAlertLog
export const getDeviceAlertLog = async deviceId => {
  if (IS_LOCAL) {
    return localDeviceAlertLogs
  }
  const option = await getAuthOption()
  return API.get(API_NAME, `/device-alert-log/${deviceId}`, option)
}

// notificationMessageLog
export const getNotificationMessageLog = async deviceId => {
  if (IS_LOCAL) {
    return localNotificationMessageLogs
  }
  const option = await getAuthOption()
  return API.get(API_NAME, `/notification-message-log/${deviceId}`, option)
}

// user.
export const getUsers = async () => {
  if (IS_LOCAL) {
    return localUsers
  }
  const option = await getAuthOption()
  return API.get(API_NAME, '/users', option)
}

export const getUser = async id => {
  if (IS_LOCAL) {
    return localUser
  }
  const option = await getAuthOption()
  return API.get(API_NAME, `/user/${id}`, option)
}

export const putUser = async body => {
  if (IS_LOCAL) {
    return localUser
  }
  const option = await getAuthOption()
  option.body = body
  return API.put(API_NAME, `/user`, option)
}

export const deleteUser = async id => {
  if (IS_LOCAL) {
    return localUser
  }
  const option = await getAuthOption()
  option.body = {
    id: id
  }
  return API.del(API_NAME, `/user`, option)
}

export const getUsersCsvData = async () => {
  if (IS_LOCAL) {
    return
  }

  const option = await getAuthOption()
  return API.get(API_NAME, '/users/csv-export', option)
}

// adminUser.
export const getAdminUsers = async () => {
  if (IS_LOCAL) {
    return localAdminUsers
  }
  const option = await getAuthOption()
  return API.get(API_NAME, '/admin-users', option)
}

export const getAdminUser = async userId => {
  if (IS_LOCAL) {
    return localAdminUsers
  }
  const option = await getAuthOption()
  return API.get(API_NAME, `/admin-user/${userId}`, option)
}

export const postAdminUser = async body => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = body
  return API.post(API_NAME, '/admin-user', option)
}

export const putAdminUser = async body => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = body
  return API.put(API_NAME, '/admin-user', option)
}

export const deleteAdminUser = async id => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = {
    id: id
  }
  return API.del(API_NAME, '/admin-user', option)
}

export const getAdminUsersCsvData = async () => {
  if (IS_LOCAL) {
    return
  }

  const option = await getAuthOption()
  return API.get(API_NAME, '/admin-users/csv-export', option)
}

// group.
export const getGroups = async () => {
  if (IS_LOCAL) {
    return localGroups
  }
  const option = await getAuthOption()
  return API.get(API_NAME, '/groups', option)
}

export const getGroup = async groupId => {
  if (IS_LOCAL) {
    return { group: localGroups.groups.find(g => g.id === groupId) }
  }
  const option = await getAuthOption()
  return API.get(API_NAME, `/group/${groupId}`, option)
}

export const postGroup = async body => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = middleware.marshalGroup(body, 'init')
  return API.post(API_NAME, '/group', option)
}

export const putGroup = async (body, type) => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  switch (type) {
    case 'base':
      option.body = middleware.marshalGroup(body, type)
      break
    case 'setting':
      option.body = middleware.marshalGroupUpdateSetting(body, type)
      break
  }
  return API.put(API_NAME, '/group', option)
}

export const deleteGroup = async body => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = body
  return API.del(API_NAME, '/group', option)
}

// setting.
export const getSettings = async () => {
  if (IS_LOCAL) {
    return localSettings
  }
  const option = await getAuthOption()
  return API.get(API_NAME, '/settings', option)
}

export const putSetting = async body => {
  if (IS_LOCAL) {
    return localSettings
  }
  const option = await getAuthOption()
  option.body = middleware.marshalSetting(body)
  return API.put(API_NAME, '/setting', option)
}

// messageDeliveries
export const getMessageDeliveries = async () => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  return API.get(API_NAME, '/message-deliveries', option)
}

export const postMessageDelivery = async body => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = body
  return API.post(API_NAME, '/message-delivery', option)
}

export const putMessageDelivery = async body => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = body
  return API.put(API_NAME, '/message-delivery', option)
}

export const deleteMessageDelivery = async ({ group_id, message_id }) => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = {
    group_id: group_id,
    message_id: message_id
  }
  return API.del(API_NAME, '/message-delivery', option)
}

// LogExport
export const getLogExports = async () => {
  if (IS_LOCAL) {
    return localLogExports
  }
  const option = await getAuthOption()
  return API.get(API_NAME, '/log-exports', option)
}

export const getLogExport = async ({ group_id, log_export_id }) => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  return API.get(API_NAME, `/log-export/${group_id}/${log_export_id}`, option)
}

export const postLogExport = async body => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = body
  return API.post(API_NAME, '/log-export', option)
}

export const deleteLogExport = async ({ group_id, log_export_id }) => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = {
    group_id: group_id,
    log_export_id: log_export_id
  }
  return API.del(API_NAME, '/log-export', option)
}

// Logger
export const postLogger = async body => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = body
  return API.post(API_NAME, '/logger', option)
}

// none auth
export const getDeviceAlertSubscriptionConfirm = async (group_id, hash) => {
  if (IS_LOCAL) {
    return
  }
  return API.get(
    API_NAME,
    `/device-alert-subscription-confirm?group_id=${group_id}&hash=${hash}`
  )
}

export const getDeviceAlertDisable = async hash => {
  if (IS_LOCAL) {
    return
  }
  return API.get(API_NAME, `/device-alert-disable?hash=${hash}`)
}

// device picture
export const getDevicePictures = async deviceId => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  return API.get(API_NAME, `/device/${deviceId}/pictures`, option)
}

export const postDevicePicture = async (deviceId, description) => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = {
    description: description
  }
  return API.post(API_NAME, `/device/${deviceId}/picture`, option)
}

export const putDevicePicture = async (deviceId, pictureId, description) => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = {
    picture_id: pictureId,
    description: description
  }
  return API.put(API_NAME, `/device/${deviceId}/picture`, option)
}

export const uploadDevicePicureToS3 = async (url, file) => {
  if (IS_LOCAL) {
    return
  }
  return axios.put(url, file, {
    headers: {
      'Content-Type': file.type
    }
  })
}

export const deleteDevicePicture = async (deviceId, pictureId) => {
  if (IS_LOCAL) {
    return
  }
  const option = await getAuthOption()
  option.body = {
    picture_id: pictureId
  }
  return API.del(API_NAME, `/device/${deviceId}/picture`, option)
}

export const getLoginHistories = async () => {
  if (IS_LOCAL) {
    return
  }

  const option = await getAuthOption()
  return API.get(API_NAME, '/login-histories', option)
}

export const getLoginHistoriesCsvData = async () => {
  if (IS_LOCAL) {
    return
  }

  const option = await getAuthOption()
  return API.get(API_NAME, '/login-histories/csv-export', option)
}

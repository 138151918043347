import { API_NAME } from '@/constants'

export default {
  Auth: {
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_AWS_COGNITO_USER_POOL_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: API_NAME,
        endpoint: process.env.VUE_APP_AWS_API_ENDPOINT
      }
    ]
  }
}

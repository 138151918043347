import * as types from './mutation-types'

export default {
  reset({ commit }) {
    commit(types.SET_ACCOUNT, {
      id: '',
      username: '',
      group: [],
      groupName: '',
      groupROle: '',
      email: ''
    })
    commit(types.SET_DEVICES, [])
    commit(types.SET_DEVICE_ALERT_LOGS, [])
    commit(types.SET_NOTIFICATION_MESSAGE_LOGS, [])
    commit(types.SET_USERS, [])
    commit(types.SET_ADMIN_USERS, [])
    commit(types.SET_GROUPS, [])
    commit(types.SET_SETTINGS, {})
    commit(types.SET_MESSAGE_DELIVERIES, [])
    commit(types.SET_LOG_EXPORTS, [])
    commit(types.SET_INFO_DEVICE_ID, '')
  },

  // account
  setAccount({ commit }, data) {
    commit(types.SET_ACCOUNT, data)
  },
  unsetAccount({ commit }) {
    commit(types.SET_ACCOUNT, {
      id: '',
      username: '',
      group: '',
      groupName: '',
      groupRole: '',
      email: ''
    })
  },

  // device.
  setDevices({ commit }, data) {
    commit(types.SET_DEVICES, data)
  },
  addDevice({ commit }, data) {
    commit(types.ADD_DEVICES, data)
  },
  updateDevice({ commit, state }, data) {
    const index = state.devices.findIndex(d => d.id === data.id)
    if (index === -1) {
      return
    }
    commit(types.UPDATE_DEVICES, { index: index, data: data })
  },
  deleteDevice({ commit, state }, data) {
    const index = state.devices.findIndex(d => d.id === data.id)
    if (index === -1) {
      return
    }
    commit(types.DELETE_DEVICES, index)
  },

  // deviceAlertLog.
  setDeviceAlertLogs({ commit }, data) {
    commit(types.SET_DEVICE_ALERT_LOGS, data)
  },

  // notificationMessageLog
  setNotificationMessageLogs({ commit }, data) {
    commit(types.SET_NOTIFICATION_MESSAGE_LOGS, data)
  },

  // user.
  setUsers({ commit }, data) {
    commit(types.SET_USERS, data)
  },
  updateUser({ commit, state }, data) {
    const index = state.users.findIndex(u => u.id === data.id)
    if (index === -1) {
      return
    }
    commit(types.UPDATE_USERS, { index: index, data: data })
  },
  deleteUser({ commit, state }, data) {
    const index = state.users.findIndex(u => u.id === data.id)
    if (index === -1) {
      return
    }
    commit(types.DELETE_USERS, index)
  },

  // adminUser.
  setAdminUsers({ commit }, data) {
    commit(types.SET_ADMIN_USERS, data)
  },
  addAdminUser({ commit }, data) {
    commit(types.ADD_ADMIN_USERS, data)
  },
  updateAdminUser({ commit, state }, data) {
    const index = state.adminUsers.findIndex(u => u.id === data.id)
    if (index === -1) {
      return
    }
    commit(types.UPDATE_ADMIN_USERS, { index: index, data: data })
  },
  deleteAdminUser({ commit, state }, data) {
    const index = state.adminUsers.findIndex(u => u.id === data.id)
    if (index === -1) {
      return
    }
    commit(types.DELETE_ADMIN_USERS, index)
  },

  // group.
  setGroups({ commit }, data) {
    commit(types.SET_GROUPS, data)
  },
  addGroup({ commit }, data) {
    commit(types.ADD_GROUPS, data)
  },
  updateGroup({ commit, state }, data) {
    const index = state.groups.findIndex(d => d.id === data.id)
    if (index === -1) {
      return
    }
    commit(types.UPDATE_GROUPS, { index: index, data: data })
  },
  deleteGroup({ commit, state }, data) {
    const index = state.groups.findIndex(o => o.name === data.name)
    if (index === -1) {
      return
    }
    commit(types.DELETE_GROUPS, index)
  },

  // settings
  setSettings({ commit }, data) {
    commit(types.SET_SETTINGS, data)
  },

  // messageDeliveries
  setMessageDeliveries({ commit }, data) {
    commit(types.SET_MESSAGE_DELIVERIES, data)
  },
  addMessageDelivery({ commit }, data) {
    commit(types.ADD_MESSAGE_DELIVERY, data)
  },
  updateMessageDelivery({ commit, state }, data) {
    const index = state.messageDeliveries.findIndex(m => {
      return m.group_id === data.group_id && m.message_id === data.message_id
    })
    if (index === -1) {
      return
    }
    commit(types.UPDATE_MESSAGE_DELIVERY, { index: index, data: data })
  },
  deleteMessageDelivery({ commit, state }, data) {
    const index = state.messageDeliveries.findIndex(m => {
      return m.group_id === data.group_id && m.message_id === data.message_id
    })
    if (index === -1) {
      return
    }
    commit(types.DELETE_MESSAGE_DELIVERY, index)
  },

  // logExports
  setLogExports({ commit }, data) {
    commit(types.SET_LOG_EXPORTS, data)
  },
  addLogExport({ commit }, data) {
    commit(types.ADD_LOG_EXPORT, data)
  },
  deleteLogExport({ commit, state }, data) {
    const index = state.logExports.findIndex(log => {
      return (
        log.group_id === data.group_id &&
        log.log_export_id === data.log_export_id
      )
    })
    if (index === -1) {
      return
    }
    commit(types.DELETE_LOG_EXPORT, index)
  },

  // infoDevice
  setInfoDeviceId({ commit }, id) {
    commit(types.SET_INFO_DEVICE_ID, id)
  },
  unsetInfoDeviceId({ commit }) {
    commit(types.SET_INFO_DEVICE_ID, '')
  },

  doReloadMap({ commit }) {
    commit(types.UPDATE_RELOAD_MAP, true)
  },
  completeReloadMap({ commit }) {
    commit(types.UPDATE_RELOAD_MAP, false)
  },

  //. fullscreenLoading
  setFullscreenLoading({ commit }, value) {
    commit(types.SET_FULLSCREEN_LOADING, value)
  },

  setSearchDevices({ commit }, value) {
    commit(types.SET_SEARCH_DEVICES, value)
  },
  setSearchDevicesByGroup({ commit }, value) {
    commit(types.SET_SEARCH_DEVICES_BY_GROUP, value)
  }
}

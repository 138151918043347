import { Auth } from 'aws-amplify'
import { getAdminUser } from '@/api'
import { IS_LOCAL } from '@/constants'
import store from '@/store'

export const guardByHome = async (to, from, next) => {
  if (IS_LOCAL) {
    return next()
  }
  try {
    await checkAndStoreUser()
    return next()
  } catch (e) {
    return next('/auth/sign-in')
  }
}

export const guardByAuth = async (to, from, next) => {
  if (IS_LOCAL) {
    return next('/home')
  }
  try {
    await checkAndStoreUser()
    return next('/home')
  } catch (e) {
    return next()
  }
}

export const guardByAdmin = async (to, from, next) => {
  if (store.getters.isAdminGroup) {
    return next()
  }
  return next('/home')
}

export const handleSignOut = async (to, from, next) => {
  if (IS_LOCAL) {
    return next()
  }
  await Auth.signOut()
  store.dispatch('reset')
  return next('/auth/sign-in')
}

async function checkAndStoreUser() {
  const user = await Auth.currentAuthenticatedUser()
  const payload = user
    .getSignInUserSession()
    .getIdToken()
    .decodePayload()

  const res = await getAdminUser(payload.sub)
  store.dispatch('setAccount', {
    id: res.admin_user.id,
    username: res.admin_user.username,
    group: res.admin_user.group,
    groupName: res.admin_user.group_name,
    groupRole: res.admin_user.group_role,
    email: res.admin_user.email
  })
}

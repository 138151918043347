import * as types from './mutation-types'

export default {
  // account
  [types.SET_ACCOUNT](
    state,
    { id, username, group, groupName, groupRole, email }
  ) {
    state.account = {
      id: id,
      username: username,
      group: group,
      groupName: groupName,
      groupRole: groupRole,
      email: email
    }
  },

  // device
  [types.SET_DEVICES](state, data) {
    state.devices = data
  },
  [types.ADD_DEVICES](state, data) {
    state.devices.push(data)
  },
  [types.UPDATE_DEVICES](state, { index, data }) {
    state.devices.splice(index, 1, data)
  },
  [types.DELETE_DEVICES](state, index) {
    state.devices.splice(index, 1)
  },

  // deviceAlertLog
  [types.SET_DEVICE_ALERT_LOGS](state, data) {
    state.deviceAlertLogs = data
  },

  // notificationMessageLog
  [types.SET_NOTIFICATION_MESSAGE_LOGS](state, data) {
    state.notificationMessageLogs = data
  },

  // user
  [types.SET_USERS](state, data) {
    state.users = data
  },
  [types.UPDATE_USERS](state, { index, data }) {
    state.users.splice(index, 1, data)
  },
  [types.DELETE_USERS](state, index) {
    state.users.splice(index, 1)
  },

  // adminUser
  [types.SET_ADMIN_USERS](state, data) {
    state.adminUsers = data
  },
  [types.ADD_ADMIN_USERS](state, data) {
    state.adminUsers.push(data)
  },
  [types.UPDATE_ADMIN_USERS](state, { index, data }) {
    state.adminUsers.splice(index, 1, data)
  },
  [types.DELETE_ADMIN_USERS](state, index) {
    state.adminUsers.splice(index, 1)
  },

  // group
  [types.SET_GROUPS](state, data) {
    state.groups = data
  },
  [types.ADD_GROUPS](state, data) {
    state.groups.push(data)
  },
  [types.UPDATE_GROUPS](state, { index, data }) {
    state.groups.splice(index, 1, data)
  },
  [types.DELETE_GROUPS](state, index) {
    state.groups.splice(index, 1)
  },

  // setting
  [types.SET_SETTINGS](
    state,
    {
      device_alerting_interval_second,
      notification_message_template,
      test_notification_message_template,
      user_device_notification_interval_second,
      notification_suppression_by_second,
      notification_suppression_count,
      notification_suppression_disable_alert_second,
      number_of_alerts_to_notify_second,
      number_of_alerts_to_notify_count,
      device_alive_threshold_hour,
      device_alive_alert_message_template,
      notification_message_template_for_email,
      test_notification_message_template_for_email,
      notification_subject_template_for_email,
      test_notification_subject_template_for_email,
      device_alert_subscribe_confirm_message_template_for_email,
      device_alert_subscribe_confirm_subject_template_for_email,
      user_device_notification_interval_second_for_email,
      notification_suppression_by_second_for_email,
      notification_suppression_count_for_email,
      notification_suppression_disable_alert_second_for_email,
      threshold_for_red_battery
    }
  ) {
    state.settings = {
      notification_message_template: notification_message_template,
      test_notification_message_template: test_notification_message_template,
      device_alerting_interval_second: Number(device_alerting_interval_second),
      user_device_notification_interval_second: Number(
        user_device_notification_interval_second
      ),
      notification_suppression_by_second: Number(
        notification_suppression_by_second
      ),
      notification_suppression_count: Number(notification_suppression_count),
      notification_suppression_disable_alert_second: Number(
        notification_suppression_disable_alert_second
      ),
      number_of_alerts_to_notify_second: Number(
        number_of_alerts_to_notify_second
      ),
      number_of_alerts_to_notify_count: Number(
        number_of_alerts_to_notify_count
      ),
      device_alive_threshold_hour: Number(device_alive_threshold_hour),
      device_alive_alert_message_template: device_alive_alert_message_template,
      notification_message_template_for_email: notification_message_template_for_email,
      test_notification_message_template_for_email: test_notification_message_template_for_email,
      notification_subject_template_for_email: notification_subject_template_for_email,
      test_notification_subject_template_for_email: test_notification_subject_template_for_email,
      device_alert_subscribe_confirm_message_template_for_email: device_alert_subscribe_confirm_message_template_for_email,
      device_alert_subscribe_confirm_subject_template_for_email: device_alert_subscribe_confirm_subject_template_for_email,
      user_device_notification_interval_second_for_email: Number(
        user_device_notification_interval_second_for_email
      ),
      notification_suppression_by_second_for_email: Number(
        notification_suppression_by_second_for_email
      ),
      notification_suppression_count_for_email: Number(
        notification_suppression_count_for_email
      ),
      notification_suppression_disable_alert_second_for_email: Number(
        notification_suppression_disable_alert_second_for_email
      ),
      threshold_for_red_battery: Number(threshold_for_red_battery)
    }
  },

  // messageDeliveries
  [types.SET_MESSAGE_DELIVERIES](state, data) {
    state.messageDeliveries = data
  },
  [types.ADD_MESSAGE_DELIVERY](state, data) {
    state.messageDeliveries.push(data)
  },
  [types.UPDATE_MESSAGE_DELIVERY](state, { index, data }) {
    state.messageDeliveries.splice(index, 1, data)
  },
  [types.DELETE_MESSAGE_DELIVERY](state, index) {
    state.messageDeliveries.splice(index, 1)
  },

  // logExports
  [types.SET_LOG_EXPORTS](state, data) {
    state.logExports = data
  },
  [types.ADD_LOG_EXPORT](state, data) {
    state.logExports.push(data)
  },
  [types.DELETE_LOG_EXPORT](state, index) {
    state.logExports.splice(index, 1)
  },

  // infoDevice
  [types.SET_INFO_DEVICE_ID](state, id) {
    state.infoDeviceId = id
  },

  [types.UPDATE_RELOAD_MAP](state, isReload) {
    state.reloadMap = isReload
  },

  // fullscreenLoading
  [types.SET_FULLSCREEN_LOADING](state, value) {
    state.fullscreenLoading = value
  },

  [types.SET_SEARCH_DEVICES](state, value) {
    state.searchDevices = value
  },
  [types.SET_SEARCH_DEVICES_BY_GROUP](state, value) {
    state.searchDevicesByGroup = value
  }
}
